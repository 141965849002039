import styled from 'styled-components';

import { setTypography } from 'utils/styles/mixins';

export const Wrapper = styled.div`
  ${({ theme: { locale } }) => setTypography('body2', locale)}
  display: flex;
  align-items: center;
  position: relative;
`;

export const HeaderText = styled.div`
  max-width: 800rem;
  margin-right: 15rem;
`;
