import { AxiosHeaders } from 'axios';

import {
  Address,
  availableCurrencies,
  Currencies,
} from '@u9/bob3-shared/lib/types/api.types';
import { TimeFrameEnum } from 'components/modals/PerformanceFiltersModal/KpisTab/TimeFrame/TimeFrame';
import { Either } from 'types/helpers';

export type LoginInput = {
  workspace: string;
  method:
    | {
        mode: 'google';
        id_token: string;
      }
    | {
        mode: 'dev';
        username: string;
      }
    | { mode: 'password'; username: string; password: string };
};

export type Profile = {
  notifications: Partial<
    Record<
      (typeof LIST_NOTIFICATIONS)[number],
      { web: boolean; email: boolean; expanded_scope?: boolean }
    >
  >;
  username: string;
  table_columns: Record<string, string[]>;
};

export type AccessRight =
  | 'producer'
  | 'executive producer'
  | 'administrator'
  | 'owner'
  | 'super admin'
  | 'pitches'
  | 'project_lock'
  | 'project_closure';

export type Permission = {
  id: number;
  name: string;
  access_set: AccessRight[];
};

type AddProjectRowsType = {
  row_no?: number;
  rows: AddRowsType[];
};

type DuplicateProjectRowsType = {
  bulk_duplicate?: ProjectRow['id'][];
};

export type ProjectRowsInput = Either<
  AddProjectRowsType,
  DuplicateProjectRowsType
>;

export interface PaginatedQueryParams {
  limit: number;
  page: number;
}

export interface SortableQueryParams {
  sort_by?: string;
  desc?: boolean;
  sort_nulls_first?: boolean;
}

export interface Client extends Closable, Verifiable {
  id: number;
  name: string;
  email?: string;
  category?: string;
  unused?: boolean;
}

export interface Brand extends Closable, Verifiable {
  id: number;
  name: string;
  unused?: boolean;
}

export type User =
  | {
      account_type: 'user';
      id: number;
      permission_set: Permission[];
      name: string;
      username: string;
      public_key?: never;
      closed?: boolean;
    }
  | {
      account_type: 'service_account';
      id: number;
      permission_set: Permission[];
      name: string;
      username?: string;
      public_key: string;
      closed?: boolean;
    };

export type AddUser = Pick<
  User,
  'account_type' | 'name' | 'public_key' | 'username'
> & {
  permission_set: Permission['id'][];
};

export type UpdateUser = Partial<
  Pick<User, 'id' | 'name' | 'public_key' | 'username' | 'closed'>
> & {
  add_permission?: Permission['id'][];
  remove_permission?: Permission['id'][];
};

export interface Budget {
  total_budget: number;
  internal_total_cost: number;
  external_total_cost: number;
  markup: number;
  markup_percent: number;
  gross_profit: number;
  gross_profit_percent: number;
  margin: number;
  margin_percent: number;
  percent_complete: number;
  mfr_margin: Record<string, number>;
  true_margin: number;
  true_margin_percent: number;
  pass_through_cost?: number;
}

export interface BudgetEntry {
  date?: string;
  label: string;
  payment_status?: PaymentStatuses;
  invoice_amount: number;
  total_amount?: number;
  terms: string;
  // If terms is a string that contains a number ('5'), and date is provided, then due_date will return a date based on those 2 factors.
  due_date?: string;
  invoice_currency?: Currencies;
  invoice_number?: string;
  po_number?: string;
  row_no?: number;
  pos?: number;
}

export const projectRowTypes = {
  spacer: 'spacer',
  resource: 'resource',
  vendorService: 'vendor_service',
} as const;

type RowType =
  | {
      type: typeof projectRowTypes.spacer;
      spacer_name: string;
      vendor_service_id?: null;
      role_id?: null;
      pass_through?: null;
    }
  | {
      type: typeof projectRowTypes.resource;
      spacer_name?: null;
      vendor_service_id?: null;
      role_id?: Role['role_id'];
      pass_through?: boolean;
    }
  | {
      type: typeof projectRowTypes.vendorService;
      spacer_name?: null;
      role_id?: null;
      vendor_service_id?: VendorService['id'] | string;
      pass_through?: boolean;
    };

export type Burn = {
  value?: number;
  checkmark?: boolean;
  comment?: string;
  previous?: number;
  last_updated?: string;
};

export type ProjectRow = {
  id: number;
  description?: string;
  is_rate_sticky: boolean;
  quantity: number;
  burn?: Burn;
  rate: number;
  default_rate: number;
  markup: number;
  total: number;
  row_no: number;
  resource?: Pick<
    Resource,
    'id' | 'company_name' | 'name' | 'email' | 'mfr_id' | 'rate'
  > & { verification_status?: VerificationStatusType } & Closable;
  resource_type?: ResourceType;
  mfr_id?: number;
  role_id?: Role['role_id'];
  role_name?: Role['role'];
  role_type?: Role['role_type'];
  vendor?: Vendor & Closable;
  vendor_service?: VendorService & Closable & Verifiable;
  true_margin_rate?: number;
  role: {
    id: Role['role_id'];
    name: Role['role'];
    type: Role['role_type'];
  } & Verifiable &
    Closable;
  expense_rule?:
    | {
        sticky: boolean;
        rule:
          | 'every_month'
          | 'every_week'
          | 'days_after_event'
          | 'days_before_event';
        value: number;
      }
    | {
        sticky: boolean;
        rule: 'on_custom_dates';
        value: {
          amount: number;
          date: string;
        }[];
      };
} & RowType;

export type ProjectRowUpdate = {
  id: ProjectRow['id'];
  description?: ProjectRow['description'];
  resource_type?: ProjectRow['resource_type'];
  is_rate_sticky?: ProjectRow['is_rate_sticky'];
  mfr_id?: ProjectRow['mfr_id'];
  quantity?: ProjectRow['quantity'];
  burn?: ProjectRow['burn'];
  rate?: ProjectRow['rate'];
  markup?: ProjectRow['markup'];
  total?: ProjectRow['total'];
  row_no?: ProjectRow['row_no'];
  role_id?: ProjectRow['role_id'];
  role_name?: ProjectRow['role_name'];
  role_type?: ProjectRow['role_type'];
  resource_id?: Resource['id'];
  vendor_service_id?: VendorService['id'];
  vendor_id?: Vendor['id'];
  bulk_move?: number;
  expense_rule?: ProjectRow['expense_rule'];
  spacer_name?: string;
  pass_through?: boolean;
};

export type ReconcileItem = {
  acc_item: string;
  jas_id: string;
  jas_description: string;
  jas_amount: number;
  row_id?: number;
  resource_id?: number;
  vendor_id?: number;
  description?: string;
  expense_type?: 'person' | 'expense';
  category?: 'expense' | 'other';
  expense_id?: string;
};

export type ReconcileExternalItem = {
  id: string;
  name: string;
  quantity: number;
  type: ReconcileIntegrationType;
  resource_id?: number;
  vendor_id?: number;
  booked_quantity?: number;
  last_booked_date?: string;
  last_reported_date?: string;
  project_name?: string;
  last_week_quantity?: number;
  future_booked_quantity?: number;
  wrap?: boolean;
  project_row_id?: ProjectRow['id'][];
};

export type ReconcileResult = {
  items: ReconcileItem[];
  external_items: ReconcileExternalItem[];
  burns: {
    id: number;
    burn: {
      jas?: number;
      float?: number;
      teamdeck?: number;
    };
  }[];
};

export type ReconcileIntegrationType = 'float' | 'teamdeck';

export type IntegrationProjectInput = {
  type: ReconcileIntegrationType;
  name: Project['name'];
};

export type IntegrationProject = {
  external_id: string;
  name: string;
};

export type ReconcileInput =
  | {
      project_id: Project['id'];
      base64: string;
      report_type: 'jas_report';
      items?: never;
    }
  | {
      project_id: Project['id'];
      report_type: 'integration';
      external_id: string;
      integration_type: 'float' | 'teamdeck';
      base64?: never;
      items?: never;
    }
  | {
      project_id: Project['id'];
      report_type: 'base';
      base64?: never;
      items?: never;
      external_items?: never;
    }
  | {
      project_id: Project['id'];
      report_type: 'base';
      items: {
        jas_id: string;
        row_id?: number;
        resource_id?: number;
        vendor_id?: number;
        description?: string;
      }[];
      external_items?: never;
      base64?: never;
    }
  | {
      project_id: Project['id'];
      report_type: 'base';
      external_items: {
        id: string;
        resource_id?: number;
        vendor_id?: number;
        wrap?: boolean;
      }[];
      items?: never;
      base64?: never;
    };

export const DEFAULT_EXTERNAL_MARKUP = 20;
export const DEFAULT_INTERNAL_MARKUP = 0;

interface Verifiable {
  verification_status?: VerificationStatusType;
}

export enum VerificationStatus {
  verified = 'verified',
  unverified = 'unverified',
}

export interface VerificationStatusType {
  status: VerificationStatus;
  message: string;
  date?: string;
}

export enum ResourceType {
  internal,
  external,
  mfr,
}

export interface Resource extends Closable, Verifiable {
  id: number;
  role_id: number;
  email: string;
  name: string;
  company_name?: string;
  unused?: boolean;
  rate?: number;
  type: ResourceType;
  mfr_id?: number;
  integration_with?: 'float' | 'teamdeck';
  department?: string;
  address?: Address;
}

export interface GeoArea {
  id: number;
  name: string;
}

export enum Territory {
  internal,
  external,
}

export interface Vertical extends Closable {
  id: number;
  name: string;
  gp_target: number;
  year: number;
}

export enum ProjectSensitivity {
  default,
  sensitive,
}

export interface ResearchAndDevelopment {
  category?: string;
  exclude?: boolean;
  percentage?: number;
  description?: string;
}

interface Closable {
  closed?: boolean;
}

export const DEFAULT_TARGET = 20;

export enum ProjectMode {
  production = 'production',
  pitch = 'pitch',
  hosting = 'hosting',
}

export enum ProjectStatus {
  active = 'active',
  closed = 'closed',
  cancelled = 'cancelled',
}

export interface Project {
  id: number;
  main_version_id: number;
  job_number?: string;
  pitch_number?: string;
  job_number_date?: string;
  name: string;
  producer: string;
  producer_email: string;
  territory: GeoArea & Closable;
  vertical: Vertical;
  client: Client;
  brand: Brand;
  mode: ProjectMode;
  status: ProjectStatus;
  updated_at: string;
  created_at: string;
  date_closed: string;
  deleted?: boolean;
  last_burn_date: string;
  contract_url?: string;
  access_request_count: number;
  access?: ProjectSensitivity;
  icon?: string;
  project_shared?: boolean;
  test_flag?: boolean;
  research_and_development?: ResearchAndDevelopment;
  custom_target?: number;
  custom_target_comment?: string;
  kickoff_date?: string;
  delivery_date?: string;
  job_number_requested?: boolean;
  job_closure_requested?: boolean;
  versions: Pick<ProjectVersion, 'id' | 'name' | 'mode'>[];
  locked?: boolean;
  date_closure_requested?: string;
  closure_state?: string;
  closure_notes?: string;
  is_watching?: boolean;
  carbon_notes?: string;
}

export interface ProjectVersion {
  id: number;
  name: string;
  rows: ProjectRow[];
  budget: Budget;
  comment: string;
  mode: Project['mode'];
  mfr_discount?: number;
  mfr_discount_reason?: string;
  merged?: boolean;
}

export type ProjectsListItem = Pick<
  Project,
  | 'id'
  | 'job_number'
  | 'pitch_number'
  | 'name'
  | 'updated_at'
  | 'producer'
  | 'mode'
  | 'status'
  | 'producer_email'
  | 'access_request_count'
  | 'access'
  | 'project_shared'
  | 'main_version_id'
  | 'job_closure_requested'
  | 'job_number_requested'
  | 'closure_notes'
  | 'closure_state'
  | 'date_closure_requested'
> & {
  vertical_name: Project['vertical']['name'];
  client?: string;
  brand?: string;
  budget: Budget;
};

export type PerformanceDashboardBudget = Pick<
  Budget,
  | 'internal_total_cost'
  | 'external_total_cost'
  | 'percent_complete'
  | 'gross_profit_percent'
  | 'margin'
  | 'margin_percent'
  | 'total_budget'
  | 'true_margin'
  | 'true_margin_percent'
  | 'pass_through_cost'
> & {
  internal_percent?: number;
  external_percent?: number;
  internal_percent_ratio?: number;
  external_percent_ratio?: number;
  roles_internal_percent_by_qty?: number;
  roles_internal_percent_by_amt?: number;
  mfr_margin?: Record<string, number>;
  mfr_margin_percent?: Record<string, number>;
};

export type PerformanceDashboardItem = Pick<
  Project,
  | 'id'
  | 'job_number'
  | 'name'
  | 'status'
  | 'updated_at'
  | 'pitch_number'
  | 'icon'
  | 'custom_target'
  | 'custom_target_comment'
> & {
  budget: PerformanceDashboardBudget;
  vertical?: Project['vertical']['name'];
  budget_history?: Record<string, PerformanceDashboardBudget>;
};

export type ProjectsPerformance = {
  projects: PerformanceDashboardItem[];
};

type AddProjectInput = {
  name?: Project['name'];
  mode?: Project['mode'];
  status?: Project['status'];
  pitch_number?: Project['pitch_number'];
  pitch_name?: ProjectVersion['name'];
  job_number?: Project['job_number'];
  vertical_id?: Vertical['id'];
  client_id?: Client['id'];
  territory_id?: GeoArea['id'];
  total_budget?: Budget['total_budget'];
  last_burn_date?: Project['last_burn_date'];
  contract_url?: Project['contract_url'];
  client?: Client['name'];
  brand?: Brand['name'];
  access?: Project['access'];
  icon?: Project['icon'];
  test_flag?: Project['test_flag'];
  research_and_development?: Project['research_and_development'];
  custom_target?: Project['custom_target'];
  custom_target_comment?: Project['custom_target_comment'];
  kickoff_date?: Project['kickoff_date'];
  delivery_date?: Project['delivery_date'];
  deleted?: Project['deleted'];
  main_version_id?: Project['main_version_id'];
  locked?: Project['locked'];
  closure_notes?: Project['closure_notes'];
  closure_state?: Project['closure_state'];
  is_watching?: Project['is_watching'];
  carbon_notes?: Project['carbon_notes'];
};

type DuplicateProjectInput = {
  duplicate_id: Project['id'];
};

export type DuplicatePitchVersionInput = {
  duplicate_id: ProjectVersion['id'];
};

export type ProjectDetails = Either<AddProjectInput, DuplicateProjectInput>;

export type ReportSourceType = 'auto' | 'manual';

export interface Report {
  id: number;
  report_date: string;
  source: 'auto' | 'manual';
}

export enum ResourceCategory {
  projectLead = 1,
  design,
  onsite,
  technical,
}

export interface ProjectComment {
  id?: number;
  user_id?: number;
  comment: string;
  created_at?: string;
  updated_at?: string;
  project_id?: Project['id'];
}

export type AddRowsType = {
  count: number;
} & RowType;

export enum PaymentStatuses {
  not_invoiced,
  invoiced,
  received,
}

export enum RoleType {
  Person,
  Other,
}

export type Role =
  | ({
      row_no: number;
      role_id: number;
      rate: number;
      role: string;
      category: string;
      role_type: RoleType;
      aliases?: string[];
      roles_request_id?: number;
      client_rates?: Record<(typeof availableCurrencies)[number], number>;
      mfr_rates: Record<MfrRate['id'], number>;
      rnd_percent?: number;
      true_margin_rate?: number;
      unused?: boolean;
    } & Verifiable)
  | {
      row_no: number;
      category: string;
      role_id?: never;
      rate?: never;
      role?: never;
      role_type?: never;
      aliases?: never;
      roles_request_id?: never;
      client_rates?: never;
      mfr_rates: never;
      rnd_percent?: never;
      true_margin_rate?: never;
      unused?: never;
      verification_status?: never;
    };

export interface MfrRate {
  id: number;
  code: string;
}

export interface VendorService extends Verifiable {
  id: number | string;
  name: string;
  aliases?: string[];
  unused?: boolean;
}

export interface Vendor extends Closable, Verifiable {
  id: number;
  name: string;
  email: string;
  service_id: VendorService['id'];
  service?: VendorService;
  resource_type: ResourceType;
  address?: Address;
  carbon_notes?: string;
  unused?: boolean;
}

export interface VendorUpdate {
  id: Vendor['id'];
  email?: Vendor['email'];
  name?: Vendor['name'];
  service_id?: VendorService['id'];
  address?: Vendor['address'];
  carbon_notes?: Vendor['carbon_notes'];
  closed?: Vendor['closed'];
  verification_status?: Vendor['verification_status'];
  unused?: Vendor['unused'];
}

export enum ProjectAccessRequestState {
  denied = -1,
  asked = 0,
  granted = 1,
}
export interface ProjectAccessRequest {
  id: number;
  username: string;
  name: string;
  project_name: Project['name'];
  project_id: Project['id'];
  status: ProjectAccessRequestState;
}

export interface ProjectPermission {
  username: string;
}

export interface AdminAlerts {
  access_requests: number;
  bob_access_requests: number;
  unverified: {
    resources_external: number;
    resources_internal: number;
    clients: number;
    brands: number;
    vendors: number;
    roles: number;
    vendor_services: number;
  };
  unused: {
    resources_external: number;
    resources_internal: number;
    clients: number;
    brands: number;
    vendors: number;
    roles: number;
    vendor_services: number;
  };
  duplicates: {
    resources: number;
    clients: number;
    brands: number;
    vendors: number;
  };
}

export interface RolesSuggestions {
  roles: Role['role_id'][];
  vendor_services: VendorService['id'][];
}

export interface BobAccessRequest {
  username: string;
  message: string;
  workspace: string;
  full_name: string;
}

export interface RoleRequest {
  id: number;
  name: string;
  category: string;
  message?: string;
}

export interface PerformanceDashboardInput {
  startDate: string;
  endDate: string;
  includeStart?: boolean;
  includeEnd?: boolean;
  includeStatuses?: Project['status'][];
  includeModes: Project['mode'][];
  verticals?: Vertical['id'][];
  budgetFrom?: number | null;
  budgetTo?: number | null;
  marginVariation?: number | null;
  timeframe?: TimeFrameEnum | null;
  similarProjectId?: Project['id'] | null;
  internalPercent?: number[] | null;
  threshold?: number;
  icons?: string[];
  includeNonIcon?: boolean;
  resultsByDate?: boolean;
}

export type NotificationCategories = 'project' | 'requests' | 'admin';

// After adding NotificationType here, you should also add it to one of the following:
// - LIST_NOTIFICATIONS
// - POPUP_NOTIFICATIONS
// - HELPER_NOTIFICATIONS
export enum NotificationTypes {
  accessRequest = 'project.access_request',
  accessGranted = 'project.access_granted',
  projectDeleted = 'project.deleted',
  bobAccessRequest = 'bob.access_request',
  newResource = 'resource.new',
  resourceDeclined = 'resource.rejected',
  resourceApproved = 'resource.approved',
  resourceSuspended = 'resource.suspended',
  bobAccessGranted = 'bob.access_granted',
  brandSuggestion = 'brand.new',
  clientSuggestion = 'client.new',
  roleRequest = 'role_request.new',
  clientAccepted = 'client.approved',
  clientRejected = 'client.rejected',
  brandAccepted = 'brand.approved',
  brandRejected = 'brand.rejected',
  vendorServiceSuggestion = 'vendor_service.new',
  vendorServiceAccepted = 'vendor_service.approved',
  vendorServiceRejected = 'vendor_service.rejected',
  vendorSuggestion = 'vendor.new',
  vendorAccepted = 'vendor.approved',
  vendorDeclined = 'vendor.rejected',
  roleRejected = 'role.rejected',
  roleApproved = 'role.approved',
  jobNumberApproved = 'job_number.approved',
  projectPaymentReceived = 'project.payment_received',
  jobClosureApproved = 'job_closure.approved',
  permissionsChanged = 'permissions.changed',
  projectUnconnected = 'project.unconnected',
  outstandingBurn = 'project.outstanding_burn',
}

export const LIST_NOTIFICATIONS = [
  NotificationTypes.accessRequest,
  NotificationTypes.accessGranted,
  NotificationTypes.projectDeleted,
  NotificationTypes.bobAccessRequest,
  NotificationTypes.resourceSuspended,
  NotificationTypes.newResource,
  NotificationTypes.resourceDeclined,
  NotificationTypes.resourceApproved,
  NotificationTypes.brandSuggestion,
  NotificationTypes.clientSuggestion,
  NotificationTypes.vendorServiceSuggestion,
  NotificationTypes.vendorServiceAccepted,
  NotificationTypes.vendorServiceRejected,
  NotificationTypes.vendorSuggestion,
  NotificationTypes.vendorAccepted,
  NotificationTypes.vendorDeclined,
  NotificationTypes.roleRequest,
  NotificationTypes.clientAccepted,
  NotificationTypes.clientRejected,
  NotificationTypes.brandAccepted,
  NotificationTypes.brandRejected,
  NotificationTypes.roleRejected,
  NotificationTypes.roleApproved,
  NotificationTypes.jobNumberApproved,
  NotificationTypes.projectPaymentReceived,
  NotificationTypes.jobClosureApproved,
] as const;

const POPUP_NOTIFICATIONS = [NotificationTypes.permissionsChanged] as const;

const HELPER_NOTIFICATIONS = [
  NotificationTypes.projectUnconnected,
  NotificationTypes.outstandingBurn,
] as const;

export const isListNotification = (
  notification: NotificationTypes
): notification is (typeof LIST_NOTIFICATIONS)[number] => {
  return LIST_NOTIFICATIONS.some(list => list === notification);
};

export const isPopupNotification = (
  notification: NotificationTypes
): notification is (typeof POPUP_NOTIFICATIONS)[number] => {
  return POPUP_NOTIFICATIONS.some(list => list === notification);
};

export const isHelperNotification = (
  notification: NotificationTypes
): notification is (typeof HELPER_NOTIFICATIONS)[number] => {
  return HELPER_NOTIFICATIONS.some(list => list === notification);
};
export interface NotificationBase {
  id: string;
  time: string;
  msg_code: NotificationTypes;
  seen: boolean;
  user_id: number;
}

export type ProjectAccessRequestNotification = NotificationBase & {
  extra_info: {
    fullName: User['name'];
    username: User['username'];
    projectName: Project['name'];
    projectId: Project['id'];
  };
};

export type ProjectAccessGrantedNotification = NotificationBase & {
  extra_info: {
    username: User['name'];
    projectName: Project['name'];
    projectId: Project['id'];
  };
};

export type ProjectDeletedNotification = NotificationBase & {
  extra_info: {
    deletedBy: User['name'];
    projectName: Project['name'];
  };
};

export type BobAccessRequestNotification = NotificationBase & {
  extra_info: { username: User['name'] };
};

export type ResourceSuspendedNotification = NotificationBase & {
  extra_info: {
    resourceName: Resource['name'];
    resourceId: Resource['id'];
    adminName: User['name'];
  };
};

export type ResourceDeclinedNotification = NotificationBase & {
  extra_info: {
    username: User['username'];
    fullName: User['name'];
    resourceName: Resource['name'];
    resourceId: Resource['id'];
  };
};

export type ResourceAcceptedNotification = NotificationBase & {
  extra_info: {
    username: User['username'];
    fullName: User['name'];
    resourceName: Resource['name'];
    resourceId: Resource['id'];
  };
};

export type BobAccessGrantedNotification = NotificationBase;

export type NewResourceNotification = NotificationBase & {
  extra_info: {
    resourceName: Resource['name'];
    resourceId: Resource['id'];
    resourceType: Resource['type'];
  };
};

export type BrandRequestNotification = NotificationBase & {
  extra_info: {
    username: User['username'];
    fullName: User['name'];
    brandName: Brand['name'];
  };
};

export type ClientRequestNotification = NotificationBase & {
  extra_info: {
    username: User['username'];
    fullName: User['name'];
    clientName: Client['name'];
  };
};

export type RoleRequestNotification = NotificationBase & {
  extra_info: {
    username: User['username'];
    fullName: User['name'];
    roleName: Role['role'];
  };
};

export type ClientRequestAcceptedNotification = NotificationBase & {
  extra_info: {
    username: User['username'];
    fullName: User['name'];
    clientName: Client['name'];
  };
};

export type ClientRequestRejectedNotification = NotificationBase & {
  extra_info: {
    username: User['username'];
    fullName: User['name'];
    clientName: Client['name'];
    message?: string;
  };
};

export type BrandRequestAcceptedNotification = NotificationBase & {
  extra_info: {
    username: User['username'];
    fullName: User['name'];
    brandName: Brand['name'];
  };
};

export type BrandRequestRejectedNotification = NotificationBase & {
  extra_info: {
    username: User['username'];
    fullName: User['name'];
    brandName: Brand['name'];
    message?: string;
  };
};

export type RoleRequestRejectedNotification = NotificationBase & {
  extra_info: {
    username: User['username'];
    fullName: User['name'];
    roleName: Role['role'];
    message?: string;
  };
};

export type VendorServiceSuggestionNotification = NotificationBase & {
  extra_info: {
    username: User['username'];
    fullName: User['name'];
    vendorServiceName: VendorService['name'];
  };
};

export type VendorServiceAcceptedNotification = NotificationBase & {
  extra_info: {
    username: User['username'];
    fullName: User['name'];
    vendorServiceName: VendorService['name'];
  };
};

export type VendorServiceRejectedNotification = NotificationBase & {
  extra_info: {
    username: User['username'];
    fullName: User['name'];
    vendorServiceName: VendorService['name'];
    message?: string;
  };
};

export type RoleRequestApprovedNotification = NotificationBase & {
  extra_info: {
    username: User['username'];
    fullName: User['name'];
    roleName: Role['role'];
  };
};

export type JobNumberApprovedNotification = NotificationBase & {
  extra_info: {
    username: User['username'];
    fullName: User['name'];
    projectId: Project['id'];
    projectName: Project['name'];
  };
};

export type ProjectPaymentReceivedNotification = NotificationBase & {
  extra_info: {
    username: User['username'];
    fullName: User['name'];
    projectId: Project['id'];
    projectName: Project['name'];
    paymentId: Payment['id'];
    amount: number;
    date: string;
  };
};

export type JobClosureApproved = NotificationBase & {
  extra_info: {
    username: User['username'];
    fullName: User['name'];
    projectId: Project['id'];
    projectName: Project['name'];
  };
};

export type PermissionsChanged = NotificationBase & {
  extra_info: {
    accessRightsGranted: AccessRight[];
    accessRightsRemoved: AccessRight[];
  };
};

export type ProjectUnconnectedNotification = NotificationBase & {
  extra_info: {
    projectId: Project['id'];
  };
};

export type OutstandingBurnNotification = NotificationBase & {
  extra_info: {
    projectId: Project['id'];
    rowIds: ProjectRow['id'][];
    resourceIds: Resource['id'][];
  };
};

export type VendorSuggestionNotification = NotificationBase & {
  extra_info: {
    username: User['username'];
    fullName: User['name'];
    vendorName: Vendor['name'];
  };
};

export type VendorAcceptedNotification = NotificationBase & {
  extra_info: {
    username: User['username'];
    fullName: User['name'];
    vendorName: Vendor['name'];
  };
};

export type VendorDeclinedNotification = NotificationBase & {
  extra_info: {
    username: User['username'];
    fullName: User['name'];
    vendorName: Vendor['name'];
    vendorId: Vendor['id'];
    message?: string;
  };
};

export type AppNotification =
  | ProjectAccessRequestNotification
  | ResourceSuspendedNotification
  | ProjectAccessGrantedNotification
  | ProjectDeletedNotification
  | BobAccessRequestNotification
  | ResourceDeclinedNotification
  | BobAccessGrantedNotification
  | NewResourceNotification
  | BrandRequestNotification
  | ClientRequestNotification
  | RoleRequestNotification
  | ClientRequestAcceptedNotification
  | ClientRequestRejectedNotification
  | BrandRequestAcceptedNotification
  | BrandRequestRejectedNotification
  | RoleRequestRejectedNotification
  | RoleRequestApprovedNotification
  | JobNumberApprovedNotification
  | ProjectPaymentReceivedNotification
  | JobClosureApproved
  | PermissionsChanged
  | ProjectUnconnectedNotification
  | OutstandingBurnNotification
  | VendorServiceSuggestionNotification
  | VendorServiceAcceptedNotification
  | VendorServiceRejectedNotification
  | ResourceAcceptedNotification
  | VendorSuggestionNotification
  | VendorAcceptedNotification
  | VendorDeclinedNotification;

export const isProjectAccessNotification = (
  notification: AppNotification
): notification is ProjectAccessRequestNotification => {
  return notification.msg_code === NotificationTypes.accessRequest;
};

export const isProjectAccessGrantedNotification = (
  notification: AppNotification
): notification is ProjectAccessGrantedNotification => {
  return notification.msg_code === NotificationTypes.accessGranted;
};

export const isProjectDeletedNotification = (
  notification: AppNotification
): notification is ProjectDeletedNotification => {
  return notification.msg_code === NotificationTypes.projectDeleted;
};

export const isResourceSuspendedNotification = (
  notification: AppNotification
): notification is ResourceSuspendedNotification => {
  return notification.msg_code === NotificationTypes.resourceSuspended;
};

export const isResourceAcceptedNotification = (
  notification: AppNotification
): notification is ResourceAcceptedNotification => {
  return notification.msg_code === NotificationTypes.resourceApproved;
};

export const isResourceDeclinedNotification = (
  notification: AppNotification
): notification is ResourceDeclinedNotification => {
  return notification.msg_code === NotificationTypes.resourceDeclined;
};

export const isBobAccessRequestNotification = (
  notification: AppNotification
): notification is BobAccessRequestNotification => {
  return notification.msg_code === NotificationTypes.bobAccessRequest;
};

export const isBobAccessGrantedNotification = (
  notification: AppNotification
): notification is BobAccessGrantedNotification => {
  return notification.msg_code === NotificationTypes.bobAccessGranted;
};

export const isNewResourceNotification = (
  notification: AppNotification
): notification is NewResourceNotification => {
  return notification.msg_code === NotificationTypes.newResource;
};

export const isBrandRequestNotification = (
  notification: AppNotification
): notification is BrandRequestNotification => {
  return notification.msg_code === NotificationTypes.brandSuggestion;
};

export const isClientRequestNotification = (
  notification: AppNotification
): notification is ClientRequestNotification => {
  return notification.msg_code === NotificationTypes.clientSuggestion;
};

export const isRoleRequestNotification = (
  notification: AppNotification
): notification is RoleRequestNotification => {
  return notification.msg_code === NotificationTypes.roleRequest;
};

export const isClientRequestAcceptedNotification = (
  notification: AppNotification
): notification is ClientRequestAcceptedNotification => {
  return notification.msg_code === NotificationTypes.clientAccepted;
};

export const isClientRequestRejectedNotification = (
  notification: AppNotification
): notification is ClientRequestRejectedNotification => {
  return notification.msg_code === NotificationTypes.clientRejected;
};

export const isBrandRequestAcceptedNotification = (
  notification: AppNotification
): notification is BrandRequestAcceptedNotification => {
  return notification.msg_code === NotificationTypes.brandAccepted;
};

export const isBrandRequestRejectedNotification = (
  notification: AppNotification
): notification is BrandRequestRejectedNotification => {
  return notification.msg_code === NotificationTypes.brandRejected;
};

export const isRoleRequestRejectedNotification = (
  notification: AppNotification
): notification is RoleRequestRejectedNotification => {
  return notification.msg_code === NotificationTypes.roleRejected;
};

export const isRoleRequestApprovedNotification = (
  notification: AppNotification
): notification is RoleRequestApprovedNotification => {
  return notification.msg_code === NotificationTypes.roleApproved;
};

export const isVendorServiceSuggestionNotification = (
  notification: AppNotification
): notification is VendorServiceSuggestionNotification => {
  return notification.msg_code === NotificationTypes.vendorServiceSuggestion;
};

export const isVendorServiceAcceptedNotification = (
  notification: AppNotification
): notification is VendorServiceAcceptedNotification => {
  return notification.msg_code === NotificationTypes.vendorServiceAccepted;
};

export const isVendorServiceRejectedNotification = (
  notification: AppNotification
): notification is VendorServiceRejectedNotification => {
  return notification.msg_code === NotificationTypes.vendorServiceRejected;
};

export const isJobNumberApprovedNotification = (
  notification: AppNotification
): notification is JobNumberApprovedNotification => {
  return notification.msg_code === NotificationTypes.jobNumberApproved;
};

export const isProjectPaymentReceivedNotification = (
  notification: AppNotification
): notification is ProjectPaymentReceivedNotification => {
  return notification.msg_code === NotificationTypes.projectPaymentReceived;
};

export const isJobClosureApprovedNotification = (
  notification: AppNotification
): notification is JobClosureApproved => {
  return notification.msg_code === NotificationTypes.jobClosureApproved;
};

export const isPermissionsChangedNotification = (
  notification: AppNotification
): notification is PermissionsChanged => {
  return notification.msg_code === NotificationTypes.permissionsChanged;
};

export const isProjectUnconnectedNotification = (
  notification: AppNotification
): notification is ProjectUnconnectedNotification => {
  return notification.msg_code === NotificationTypes.projectUnconnected;
};

export const isOutstandingBurnNotification = (
  notification: AppNotification
): notification is OutstandingBurnNotification => {
  return notification.msg_code === NotificationTypes.outstandingBurn;
};

export const isVendorSuggestionNotification = (
  notification: AppNotification
): notification is VendorSuggestionNotification => {
  return notification.msg_code === NotificationTypes.vendorSuggestion;
};

export const isVendorAcceptedNotification = (
  notification: AppNotification
): notification is VendorAcceptedNotification => {
  return notification.msg_code === NotificationTypes.vendorAccepted;
};

export const isVendorDeclinedNotification = (
  notification: AppNotification
): notification is VendorDeclinedNotification => {
  return notification.msg_code === NotificationTypes.vendorDeclined;
};

type ArchiveItemType =
  | 'user'
  | 'resource'
  | 'vendor'
  | 'client'
  | 'brand'
  | 'project';

export interface ArchiveItem {
  id: number;
  name: string;
  email: string;
  deleted_on: string;
  type: ArchiveItemType;
}

export interface ProjectFieldLists {
  mfr_discount_reason?: string[];
  custom_target_comment?: string[];
}

export interface ProjectGroup {
  id: number;
  name: string;
  projects: Project['id'][];
}

export const HISTORY_LOG_ENTITIES = {
  PROJECT: 'project',
  PROJECT_ROW: 'project.row',
  PROJECT_INVOICE: 'project.budgetentry',
  PROJECT_COMMENT: 'project.comment',
  PROJECT_ACCESS: 'project.access',
  PROJECT_NUMBER: 'project.request_job_number',
  PROJECT_RECONCILE: 'project.reconcile',
  PITCH: 'pitch',
  PITCH_ROW: 'pitch.row',
  PITCH_INVOICE: 'pitch.budgetentry',
  VENDOR: 'vendor',
  VENDOR_SERVICE: 'vendor_service',
  CLIENT: 'client',
  BRAND: 'brand',
  RESOURCE: 'resource',
  ROLE: 'role',
  USER: 'user',
  MFR_TYPE: 'mfr_type',
  VERTICAL: 'vertical',
  TERRITORY: 'territory',
} as const;

export const HISTORY_LOG_ACTIONS = {
  CREATE: 'CREATE',
  UPDATE: 'UPDATE',
  DELETE: 'DELETE',
} as const;

export interface HistoryEntry {
  id: string;
  entity: (typeof HISTORY_LOG_ENTITIES)[keyof typeof HISTORY_LOG_ENTITIES];
  entity_id: number;
  entity_name: string;
  user_name: string;
  user_email: string;
  action: (typeof HISTORY_LOG_ACTIONS)[keyof typeof HISTORY_LOG_ACTIONS];
  timestamp: string;
}

export interface PaginationHeaders extends AxiosHeaders {
  'total-count': number;
  'total-pages': number;
  'current-page': number;
}

export interface LinkableOnDeletion<T extends { id: any }> {
  parent_id?: T['id'];
}

export interface HistoryLogInput {
  entity?:
    | (typeof HISTORY_LOG_ENTITIES)[keyof typeof HISTORY_LOG_ENTITIES]
    | string;
  search?: string;
  start_time?: string;
  end_time?: string;
}

export interface CostAnalysisInput {
  type: 'resource' | 'vendor' | 'role' | 'vendor_service';
  id: number;
}

export interface CostAnalysis {
  project_id: Project['id'];
  project_name: string;
  cost: number;
  type: CostAnalysisInput['type'];
  id: number;
}

export type DuplicateType = 'resource' | 'clients' | 'brands' | 'vendors';
export interface ResourceDuplicate {
  id: number;
  name: Resource['name'];
  email: Resource['email'];
  role: Role['role'];
  role_id: Role['role_id'];
  type: Resource['type'];
  project_count: number;
}

export interface BrandDuplicate {
  id: number;
  name: Brand['name'];
  project_count: number;
}

export interface VendorDuplicate {
  id: number;
  name: Vendor['name'];
  email: Vendor['email'];
  service_id: Vendor['service_id'];
  service_name: string;
  project_count: number;
}

export interface ClientDuplicate {
  id: number;
  name: Resource['name'];
  project_count: number;
}

export type DuplicateItem =
  | ResourceDuplicate
  | BrandDuplicate
  | VendorDuplicate
  | ClientDuplicate;
export interface ResolveDuplicatesInput {
  id: number;
  action: 'split' | 'merge';
  parent_id?: number;
}

export interface CashflowItem {
  id: number;
  version_row_id: number;
  hours?: number;
  name?: string;
  start_date: string;
  end_date: string;
  weekend: boolean;
}

export interface Payment {
  id: number;
  reference?: string;
  job_number?: string;
  amount?: number;
  allocated?: boolean;
  currency?: Currencies;
  date?: string;
}

export interface ReportTemplate {
  code: 'client_report' | 'mfr_report';
  title: string;
  config: {
    company_address?: Address;
    company_name?: string;
    company_phone?: string;
    company_website?: string;
    notes?: null;
    footer?: string;
  };
}

export interface ProjectClosureStatus {
  closable: boolean;
  issues?: { code: 'contract_missing' | 'invoice_status' }[];
}

export interface TeamdeckResourceMapping {
  bob_email?: string;
  bob_id?: number;
  bob_name?: string;
  teamdeck_id: number;
  teamdeck_name: string;
}

export interface TeamdeckProjectMapping {
  bob_category: 'pitch' | 'non_project' | 'billable' | 'internal';
  id: number;
  name: string;
}
