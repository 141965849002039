import { useRouter } from 'next/router';
import { FC, memo, useMemo } from 'react';

import FormInput from 'components/inputs/FormInput/FormInput';
import { useField } from 'components/inputs/hooks/useField';
import { useAddProjectGroup } from 'queries/projectGroups';
import { useCopyStore, useGlobalStore } from 'store';
import { getProjectsListGroupRoute } from 'utils/routes';

import ConfigModal from '../ConfigModal/ConfigModal';

import * as Styled from './AddProjectGroup.styles';

export interface AddProjectGroupProps {}

const AddProjectGroup: FC<AddProjectGroupProps> = () => {
  const router = useRouter();
  const copy = useCopyStore(s => s.copy);
  const closeModal = useGlobalStore(s => s.closeModal);
  const currentColor = useGlobalStore(s => s.currentColor);
  const validators = useMemo(
    () => ({
      required: {
        enabled: true,
        message: copy.app.validatorMessages.fieldRequired,
      },
    }),
    [copy.app.validatorMessages.fieldRequired]
  );
  const [pitchName, setGroupName, isGroupNameValid, invalidMessage] = useField(
    '',
    validators
  );

  const { mutateAsync: addProjectGroup, isLoading } = useAddProjectGroup();

  const handleSave = async () => {
    if (!isGroupNameValid) return;
    const { data: projectGroup } = await addProjectGroup({
      name: pitchName,
    });
    router.push(getProjectsListGroupRoute(projectGroup.id));
    closeModal('addProjectGroup');
  };

  const modalCopy = copy.app.addProjectGroupModal;

  return (
    <ConfigModal
      title={modalCopy.title}
      buttonProps={{
        label: modalCopy.cta,
        color: currentColor,
        icon: 'check',
        loading: isLoading,
        disabled: isLoading || !isGroupNameValid,
        onClick: handleSave,
      }}
      color={currentColor}
      onCloseClick={() => closeModal('addProjectGroup')}
    >
      <Styled.Wrapper>
        <FormInput
          value={pitchName}
          onChange={setGroupName}
          placeholder={modalCopy.label}
          error={!isGroupNameValid && invalidMessage ? invalidMessage : ''}
        />
      </Styled.Wrapper>
    </ConfigModal>
  );
};

export default memo(AddProjectGroup);
