import { useRouter } from 'next/router';

import { useIsDemo } from 'hooks/useIsDemo';
import { useAdminAlerts } from 'queries/adminAlerts';
import { useProjectGroups } from 'queries/projectGroups';
import { useApiStore, useCopyStore } from 'store';
import { useSvgIconList } from 'u9/hooks';
import { useCheckAccessRights } from 'utils/accessRights';
import {
  getAdminRoute,
  getFinanceRoute,
  getPerformanceRoute,
  getProjectsListGroupRoute,
  getReportsRoute,
  ROUTES,
} from 'utils/routes';
import { sortString } from 'utils/sort';

import AddProjectGroupButton from '../AddProjectGroupButton/AddProjectGroupButton';
import { NavigationItem, SubmenuItem } from '../DefaultNavigation';

export const useDefaultItems = () => {
  const copy = useCopyStore(s => s.copy);
  const router = useRouter();
  const { data: projectGroups } = useProjectGroups();
  const isApiReady = useApiStore(s => s.apiClient.isReady);
  const isDemo = useIsDemo();

  const navCopy = copy.app.navigation;

  const [WalletIcon, GraphIcon, DocumentIcon, AdminIcon, BooksIcon] =
    useSvgIconList(['wallet', 'graph', 'document', 'admin', 'books']);
  const { checkAccessRight, checkAnyAccessRight } = useCheckAccessRights();

  const isAtLeastInternal = checkAnyAccessRight([
    'administrator',
    'executive producer',
    'owner',
    'super admin',
  ]);

  const isAtLeastAdmin = checkAnyAccessRight([
    'administrator',
    'owner',
    'super admin',
  ]);

  const isOwner = checkAccessRight('owner');
  const isSuperAdmin = checkAccessRight('super admin');

  const adminAlertsQuery = useAdminAlerts({
    enabled: isAtLeastAdmin && isApiReady,
  });

  const adminAlerts = adminAlertsQuery.data;

  const navigationItems = new Set<NavigationItem>();

  const projectsListSubmenu = new Set<SubmenuItem>();

  projectsListSubmenu.add({
    label: copy.app.navigation.jobNumberPending,
    route: ROUTES.PROJECTS_LIST_JOB_NUMBER_PENDING,
  });

  projectsListSubmenu.add({
    label: copy.app.navigation.jobClosurePending,
    route: ROUTES.PROJECTS_LIST_JOB_CLOSURE_PENDING,
  });

  if (projectGroups) {
    projectsListSubmenu.add({
      label: copy.app.navigation.projectGroups,
      isGroupHeader: true,
    });

    projectGroups
      .sort((a, b) => sortString(a.name, b.name, 'asc'))
      .forEach(group => {
        projectsListSubmenu.add({
          label: `${group.name} (${group.projects.length})`,
          route: getProjectsListGroupRoute(group.id),
        });
      });
  }

  navigationItems.add({
    label: navCopy.projects,
    Icon: WalletIcon,
    route: ROUTES.PROJECTS_LIST,
    id: ROUTES.PROJECTS_LIST,
    submenu: projectsListSubmenu,
    endHtml: <AddProjectGroupButton />,
  });

  // Performance Dashboard
  if (isAtLeastInternal) {
    const submenu = new Set<SubmenuItem>();

    if (isOwner || isSuperAdmin) {
      submenu.add({
        label: navCopy.overview,
        route: getPerformanceRoute('OVERVIEW'),
      });
    }

    submenu.add({
      label: navCopy.live,
      route: getPerformanceRoute('LIVE'),
    });

    if (isOwner || isSuperAdmin) {
      submenu.add({
        label: navCopy.pulse,
        route: getPerformanceRoute('PULSE'),
      });

      if (!isDemo) {
        submenu.add({
          label: navCopy.insight,
          route: getPerformanceRoute('INSIGHT'),
        });
      }
    }

    navigationItems.add({
      label: navCopy.performance,
      Icon: GraphIcon,
      submenu,
      id: ROUTES.PERFORMANCE,
      route: getPerformanceRoute('LIVE'),
    });
  }

  // Reports
  if (isAtLeastAdmin) {
    const submenu = new Set<SubmenuItem>();

    submenu.add({
      label: navCopy.projectReports,
      route: getReportsRoute('AUTOMATIC'),
    });

    navigationItems.add({
      label: navCopy.reports,
      Icon: DocumentIcon,
      submenu,
      id: ROUTES.REPORTS_LIST,
      route: getReportsRoute('ALL'),
    });
  }

  // Finance
  if (isOwner || isSuperAdmin) {
    const submenu = new Set<SubmenuItem>();

    submenu.add({
      label: navCopy.financePayments,
      route: getFinanceRoute('PAYMENTS'),
    });

    navigationItems.add({
      label: navCopy.finance,
      Icon: BooksIcon,
      submenu,
      id: ROUTES.FINANCE,
      route: getFinanceRoute('HOME'),
    });
  }

  // Admin Panel
  if (isAtLeastAdmin) {
    const submenu = new Set<SubmenuItem>();

    const userAlertCounter =
      (adminAlerts?.access_requests || 0) +
      (adminAlerts?.bob_access_requests || 0);
    const resourceAlertCounter =
      (adminAlerts?.unverified.resources_internal || 0) +
      (adminAlerts?.unused.resources_external || 0) +
      (adminAlerts?.unverified.resources_external || 0) +
      (adminAlerts?.unused.resources_internal || 0) +
      (adminAlerts?.duplicates.resources || 0);
    const vendorAlertCounter =
      (adminAlerts?.unverified.vendors || 0) +
      (adminAlerts?.unused.vendors || 0) +
      (adminAlerts?.duplicates.vendors || 0);
    const clientAlertCounter =
      (adminAlerts?.unverified.clients || 0) +
      (adminAlerts?.unused.clients || 0) +
      (adminAlerts?.duplicates.clients || 0);
    const brandAlertCounter =
      (adminAlerts?.unverified.brands || 0) +
      (adminAlerts?.duplicates.brands || 0) +
      (adminAlerts?.unused.brands || 0);
    const rolesAlertCounter =
      (adminAlerts?.unverified.roles || 0) + (adminAlerts?.unused.roles || 0);
    const vendorServiceAlertCounter =
      (adminAlerts?.unverified.vendor_services || 0) +
      (adminAlerts?.unused.vendor_services || 0);

    submenu
      .add({
        label: navCopy.adminUsers,
        route: getAdminRoute('USERS'),
        count: userAlertCounter,
      })
      .add({
        label: navCopy.adminResources,
        route: getAdminRoute('INTERNAL'),
        count: resourceAlertCounter,
      })
      .add({
        label: navCopy.adminVendors,
        route: getAdminRoute('VENDOR'),
        count: vendorAlertCounter,
      })
      .add({
        label: navCopy.adminClients,
        route: getAdminRoute('CLIENTS'),
        count: clientAlertCounter,
      })
      .add({
        label: navCopy.adminBrands,
        route: getAdminRoute('BRANDS'),
        count: brandAlertCounter,
      })
      .add({ label: navCopy.adminArchive, route: getAdminRoute('ARCHIVE') })
      .add({ label: navCopy.historyLog, route: getAdminRoute('HISTORY') })
      .add({
        label: navCopy.adminRoles,
        route: getAdminRoute('ROLES'),
        count: rolesAlertCounter,
      })
      .add({
        label: navCopy.adminVendorServices,
        route: getAdminRoute('VENDOR_SERVICE'),
        count: vendorServiceAlertCounter,
      })
      .add({
        label: navCopy.adminTerritory,
        route: getAdminRoute('TERRITORY'),
      })
      .add({ label: navCopy.adminVerticals, route: getAdminRoute('VERTICAL') });

    if (isOwner || isSuperAdmin) {
      submenu.add({
        label: navCopy.reportsConfiguration,
        route: getAdminRoute('REPORTS_CONFIGURATION'),
      });

      if (!isDemo) {
        submenu.add({
          label: navCopy.teamdeckConfiguration,
          route: getAdminRoute('TEAMDECK_CONFIGURATION'),
        });
      }

      submenu
        .add({
          label: navCopy.integrations,
          route: getAdminRoute('INTEGRATIONS'),
        })
        .add({
          label: navCopy.userPermissions,
          route: getAdminRoute('USER_PERMISSIONS'),
        })
        .add({
          label: navCopy.adminSettings,
          route: getAdminRoute('WORKSPACE_SETTINGS'),
        });
    }

    navigationItems.add({
      label: navCopy.admin,
      Icon: AdminIcon,
      submenu,
      id: ROUTES.ADMIN,
      route: getAdminRoute('HOME'),
    });
  }

  const getIsRouteActive = (route: string) => {
    return !!router.asPath.match(
      new RegExp(`^${route}/|^${route}$|^${route}\\?`, 'gi')
    );
  };

  return { navigationItems, getIsRouteActive };
};
