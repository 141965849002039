import _groupBy from 'lodash/groupBy';
import { CSSProperties, FC, memo, useEffect, useMemo, useState } from 'react';

import SearchInput from 'components/inputs/SearchInput/SearchInput';
import ScrollArea from 'components/ScrollArea/ScrollArea';
import { useFilter } from 'hooks/userFilterData';
import {
  useNotificationsForList,
  useUpdateNotifications,
} from 'queries/notifications';
import { useCopyStore } from 'store';
import { useSvgIcon } from 'u9/hooks';
import {
  isBobAccessGrantedNotification,
  isBobAccessRequestNotification,
  isBrandRequestAcceptedNotification,
  isBrandRequestNotification,
  isBrandRequestRejectedNotification,
  isClientRequestAcceptedNotification,
  isClientRequestNotification,
  isClientRequestRejectedNotification,
  isJobClosureApprovedNotification,
  isJobNumberApprovedNotification,
  isNewResourceNotification,
  isProjectAccessGrantedNotification,
  isProjectAccessNotification,
  isProjectDeletedNotification,
  isProjectPaymentReceivedNotification,
  isResourceAcceptedNotification,
  isResourceDeclinedNotification,
  isResourceSuspendedNotification,
  isRoleRequestApprovedNotification,
  isRoleRequestNotification,
  isRoleRequestRejectedNotification,
  isVendorAcceptedNotification,
  isVendorDeclinedNotification,
  isVendorServiceAcceptedNotification,
  isVendorServiceRejectedNotification,
  isVendorServiceSuggestionNotification,
  isVendorSuggestionNotification,
} from 'utils/api.types';
import { replaceString } from 'utils/replace';
import { sortDates } from 'utils/sort';

import { useFormatTimePassed } from '../hooks/useFormatTimePassed';
import BobAccessGrantedNotification from '../notifications/BobAccessGrantedNotification/BobAccessGrantedNotification';
import BobAccessRequestNotification from '../notifications/BobAccessRequestNotification/BobAccessRequestNotification';
import BrandRequestAcceptedNotification from '../notifications/BrandRequestAcceptedNotification/BrandRequestAcceptedNotification';
import BrandRequestNotification from '../notifications/BrandRequestNotification/BrandRequestNotification';
import BrandRequestRejectedNotification from '../notifications/BrandRequestRejectedNotification/BrandRequestRejectedNotification';
import ClientRequestAcceptedNotification from '../notifications/ClientRequestAcceptedNotification/ClientRequestAcceptedNotification';
import ClientRequestNotification from '../notifications/ClientRequestNotification/ClientRequestNotification';
import ClientRequestRejectedNotification from '../notifications/ClientRequestRejectedNotification/ClientRequestRejectedNotification';
import JobClosureRequestApprovedNotification from '../notifications/JobClosureRequestApprovedNotification/JobClosureRequestApprovedNotification';
import JobNumberApprovedNotification from '../notifications/JobNumberApprovedNotification/JobNumberApprovedNotification';
import NewResourceNotification from '../notifications/NewResourceNotification/NewResourceNotification';
import ProjectAccessGrantedNotification from '../notifications/ProjectAccessGrantedNotification/ProjectAccessGrantedNotification';
import ProjectAccessRequestNotification from '../notifications/ProjectAccessRequestNotification/ProjectAccessRequestNotification';
import ProjectDeletedNotification from '../notifications/ProjectDeletedNotification/ProjectDeletedNotification';
import ProjectPaymentReceivedNotification from '../notifications/ProjectPaymentReceivedNotification/ProjectPaymentReceivedNotification';
import ResourceAcceptedNotification from '../notifications/ResourceAcceptedNotification/ResourceAcceptedNotification';
import ResourceDeclinedNotification from '../notifications/ResourceDeclinedNotification/ResourceDeclinedNotification';
import ResourceSuspendedNotification from '../notifications/ResourceSuspendedNotification/ResourceSuspendedNotification';
import RoleRequestApprovedNotification from '../notifications/RoleRequestApprovedNotification/RoleRequestApprovedNotification';
import RoleRequestNotification from '../notifications/RoleRequestNotification/RoleRequestNotification';
import RoleRequestRejectedNotification from '../notifications/RoleRequestRejectedNotification/RoleRequestRejectedNotification';
import VendorRequestAcceptedNotification from '../notifications/VendorRequestAcceptedNotification/VendorRequestAcceptedNotification';
import VendorRequestDeclinedNotification from '../notifications/VendorRequestDeclinedNotification/VendorRequestDeclinedNotification';
import VendorRequestNotification from '../notifications/VendorRequestNotification/VendorRequestNotification';
import VendorServiceApprovedNotification from '../notifications/VendorServiceApprovedNotification/VendorServiceApprovedNotification';
import VendorServiceRejectedNotification from '../notifications/VendorServiceRejectedNotification/VendorServiceRejectedNotification';
import VendorServiceRequestNotification from '../notifications/VendorServiceRequestNotification/VendorServiceRequestNotification';

import * as Styled from './NotificationsList.styles';

export interface NotificationsListProps {
  handleCloseButtonClick: () => void;
  popupStyle: CSSProperties;
}

const NotificationsList: FC<NotificationsListProps> = ({
  handleCloseButtonClick,
  popupStyle,
}) => {
  const { SvgIcon: CloseIcon } = useSvgIcon('close');
  const { data: notifications } = useNotificationsForList();
  const { mutateAsync: updateNotifications } = useUpdateNotifications();
  const copy = useCopyStore(s => s.copy);
  const locale = useCopyStore(s => s.locale);
  const { formatTimePassed } = useFormatTimePassed();
  const [searchText, setSearchText] = useState('');

  const isYesterday = (date: Date) => {
    const yesterday = new Date();
    yesterday.setDate(yesterday.getDate() - 1);

    return yesterday.toDateString() === date.toDateString();
  };

  const isToday = (date: Date) => {
    const today = new Date();
    return today.toDateString() === date.toDateString();
  };

  const formatTime = (date: string) => {
    const d = new Date(date);

    if (isToday(d)) {
      return copy.app.timePassed.today;
    }
    if (isYesterday(d)) {
      return copy.app.timePassed.yesterday;
    }
    return Intl.DateTimeFormat(locale, {
      day: '2-digit',
      month: 'long',
      year: 'numeric',
    }).format(d);
  };

  useEffect(() => {
    return () => {
      const unseenNotifications = notifications?.filter(({ seen }) => !seen);
      if (unseenNotifications && unseenNotifications.length > 0) {
        updateNotifications(
          unseenNotifications.map(notification => ({
            id: notification.id,
            seen: true,
          }))
        );
      }
    };
  }, [notifications, updateNotifications]);

  const notificationsCopy = copy.app.notifications;

  const notificationsFilterReady = useMemo(
    () =>
      notifications?.map(notification => {
        let copy1 = '';
        let copy2 = '';
        if (isProjectAccessNotification(notification)) {
          copy1 = notificationsCopy.projectAccessRequest;
        }
        if (isResourceSuspendedNotification(notification)) {
          copy1 = notificationsCopy.resourceSuspended;
          copy2 = notificationsCopy.resourceSuspendedButton;
        }
        if (isProjectAccessGrantedNotification(notification))
          copy1 = notificationsCopy.projectAccessGranted;
        if (isProjectDeletedNotification(notification))
          copy1 = notificationsCopy.projectDeleted;
        if (isBobAccessRequestNotification(notification)) {
          copy1 = notificationsCopy.bobAccessRequest;
          copy2 = notificationsCopy.bobAccessRequestButton;
        }

        if (isResourceDeclinedNotification(notification)) {
          copy1 = notificationsCopy.resourceDeclined;
          copy2 = notificationsCopy.resourceDeclinedButton;
        }
        if (isBobAccessGrantedNotification(notification)) {
          copy1 = notificationsCopy.bobAccessGranted;
        }
        if (isNewResourceNotification(notification)) {
          copy1 = notificationsCopy.newResource;
          copy2 = notificationsCopy.newResourceButton;
        }
        return {
          copy1,
          copy2,
          ...notification,
        };
      }),
    [notifications, notificationsCopy]
  );

  const filteredNotifications = useFilter(
    notificationsFilterReady,
    searchText,
    [
      'fullName',
      'username',
      'projectName',
      'adminName',
      'resourceName',
      'clientName',
      'brandName',
      'vendorServiceName',
      'copy1',
      'copy2',
    ]
  );

  const groupedNotifications = Object.entries(
    _groupBy(filteredNotifications, notification => {
      const date = new Date(notification.time);
      return `${date.getFullYear()} ${date.getMonth() + 1} ${date.getDate()}`;
    })
  ).sort(([dateA], [dateB]) => sortDates(dateA, dateB, 'asc'));

  return (
    <Styled.Wrapper
      style={popupStyle}
      initial={{ opacity: 0, y: '-20rem' }}
      animate={{ opacity: 1, y: '0rem' }}
      exit={{ opacity: 0, y: '-20rem' }}
      transition={{ duration: 0.15 }}
    >
      <Styled.CloseButton
        onClick={handleCloseButtonClick}
        data-cy="notifications-panel__close-button"
      >
        <Styled.CloseIconWrapper>
          <CloseIcon />
        </Styled.CloseIconWrapper>
      </Styled.CloseButton>
      <Styled.Title>{copy.app.notifications.title}</Styled.Title>
      <Styled.SearchBar>
        <SearchInput
          value={searchText}
          onChange={setSearchText}
          placeholder={replaceString(
            copy.app.searchInputPlaceholder,
            '{name}',
            copy.app.notifications.title
          )}
        />
      </Styled.SearchBar>
      <ScrollArea>
        <Styled.List>
          {groupedNotifications.map(([date, notificationsList]) => (
            <Styled.NotificationGroupWrapper key={date}>
              <Styled.NotificationGroupTitle>
                <Styled.NotificationGroupTitleText>
                  {formatTime(date)}
                </Styled.NotificationGroupTitleText>
                <Styled.NotificationGroupTitleLine />
              </Styled.NotificationGroupTitle>

              {notificationsList.map(notification => (
                <Styled.NotificationWrapper
                  key={notification.id}
                  data-cy={`notifications-panel__notification__${notification.msg_code}`}
                >
                  {isProjectAccessNotification(notification) && (
                    <ProjectAccessRequestNotification
                      notification={notification}
                    />
                  )}
                  {isResourceSuspendedNotification(notification) && (
                    <ResourceSuspendedNotification
                      notification={notification}
                    />
                  )}
                  {isProjectAccessGrantedNotification(notification) && (
                    <ProjectAccessGrantedNotification
                      notification={notification}
                    />
                  )}

                  {isProjectDeletedNotification(notification) && (
                    <ProjectDeletedNotification notification={notification} />
                  )}
                  {isBobAccessRequestNotification(notification) && (
                    <BobAccessRequestNotification notification={notification} />
                  )}
                  {isResourceDeclinedNotification(notification) && (
                    <ResourceDeclinedNotification notification={notification} />
                  )}
                  {isResourceAcceptedNotification(notification) && (
                    <ResourceAcceptedNotification notification={notification} />
                  )}
                  {isBobAccessGrantedNotification(notification) && (
                    <BobAccessGrantedNotification notification={notification} />
                  )}
                  {isNewResourceNotification(notification) && (
                    <NewResourceNotification notification={notification} />
                  )}
                  {isBrandRequestNotification(notification) && (
                    <BrandRequestNotification notification={notification} />
                  )}
                  {isClientRequestNotification(notification) && (
                    <ClientRequestNotification notification={notification} />
                  )}
                  {isRoleRequestNotification(notification) && (
                    <RoleRequestNotification notification={notification} />
                  )}
                  {isClientRequestAcceptedNotification(notification) && (
                    <ClientRequestAcceptedNotification
                      notification={notification}
                    />
                  )}
                  {isClientRequestRejectedNotification(notification) && (
                    <ClientRequestRejectedNotification
                      notification={notification}
                    />
                  )}
                  {isBrandRequestAcceptedNotification(notification) && (
                    <BrandRequestAcceptedNotification
                      notification={notification}
                    />
                  )}
                  {isBrandRequestRejectedNotification(notification) && (
                    <BrandRequestRejectedNotification
                      notification={notification}
                    />
                  )}
                  {isRoleRequestRejectedNotification(notification) && (
                    <RoleRequestRejectedNotification
                      notification={notification}
                    />
                  )}
                  {isRoleRequestApprovedNotification(notification) && (
                    <RoleRequestApprovedNotification
                      notification={notification}
                    />
                  )}
                  {isJobNumberApprovedNotification(notification) && (
                    <JobNumberApprovedNotification
                      notification={notification}
                    />
                  )}
                  {isProjectPaymentReceivedNotification(notification) && (
                    <ProjectPaymentReceivedNotification
                      notification={notification}
                    />
                  )}
                  {isJobClosureApprovedNotification(notification) && (
                    <JobClosureRequestApprovedNotification
                      notification={notification}
                    />
                  )}
                  {isVendorServiceAcceptedNotification(notification) && (
                    <VendorServiceApprovedNotification
                      notification={notification}
                    />
                  )}
                  {isVendorServiceRejectedNotification(notification) && (
                    <VendorServiceRejectedNotification
                      notification={notification}
                    />
                  )}
                  {isVendorServiceSuggestionNotification(notification) && (
                    <VendorServiceRequestNotification
                      notification={notification}
                    />
                  )}
                  {isVendorSuggestionNotification(notification) && (
                    <VendorRequestNotification notification={notification} />
                  )}
                  {isVendorAcceptedNotification(notification) && (
                    <VendorRequestAcceptedNotification
                      notification={notification}
                    />
                  )}
                  {isVendorDeclinedNotification(notification) && (
                    <VendorRequestDeclinedNotification
                      notification={notification}
                    />
                  )}
                  <Styled.NotificationEndWrapper>
                    <Styled.NotificationEndWrapperText>
                      {formatTimePassed(notification.time)}
                    </Styled.NotificationEndWrapperText>
                    {!notification.seen && (
                      <Styled.NotificationEndWrapperIcon />
                    )}
                  </Styled.NotificationEndWrapper>
                </Styled.NotificationWrapper>
              ))}
            </Styled.NotificationGroupWrapper>
          ))}
          <Styled.NotificationsExpiryLabel>
            {copy.app.notifications.expiryLabel}
          </Styled.NotificationsExpiryLabel>
        </Styled.List>
      </ScrollArea>
    </Styled.Wrapper>
  );
};

export default memo(NotificationsList);
