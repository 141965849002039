import React from 'react';

import * as Styled from './OverflowEllipsis.styles';

export interface OverflowEllipsisProps {
  text?: string | number;
  title?: string;
  styles?: React.CSSProperties;
  dataCy?: string;
  className?: string;
}

const OverflowEllipsis = ({
  text,
  title = '',
  dataCy = '',
  styles,
  className = '',
}: OverflowEllipsisProps) => {
  const displayText = text ?? '';
  const displayTitle = title ?? '';
  return (
    <Styled.Wrapper
      title={displayTitle || String(displayText)}
      style={styles}
      data-cy={dataCy}
      className={className}
    >
      {String(displayText)}
    </Styled.Wrapper>
  );
};

export default React.memo(OverflowEllipsis);
