import Link from 'next/link';
import { FC, memo } from 'react';

import Copy from 'components/Copy/Copy';
import { useNotificationHover } from 'components/Notifications/hooks/useNotificationHover';
import { useCopyStore } from 'store';
import { NewResourceNotification as NewResourceNotificationType } from 'utils/api.types';
import { getAdminRoute } from 'utils/routes';

import * as Styled from '../../NotificationItem.styles';

export interface NewResourceNotificationProps {
  notification: NewResourceNotificationType;
}

const NewResourceNotification: FC<NewResourceNotificationProps> = ({
  notification,
}) => {
  const copy = useCopyStore(s => s.copy);
  const { onMouseEnter, onMouseLeave, isHovered } = useNotificationHover();

  return (
    <Styled.Wrapper onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <Copy
        text={copy.app.notifications.newResource}
        replacements={[
          ['{resource}', notification.extra_info.resourceName],
          [
            '{button}',
            <Link
              key="button"
              href={{
                pathname: getAdminRoute('RESOURCE_REQUESTS'),
              }}
              prefetch={false}
            >
              <Styled.NotificationInlineButton underlined={isHovered}>
                {copy.app.notifications.newResourceButton}
              </Styled.NotificationInlineButton>
              ,
            </Link>,
          ],
        ]}
      />
    </Styled.Wrapper>
  );
};

export default memo(NewResourceNotification);
