export enum LocalStorageKeys {
  BOB_TABLE_COLUMNS = 'BOB_TABLE_COLUMNS',
  BOB_RECONCILE_SELECTION = 'BOB_RECONCILE_SELECTION',
  WHATS_NEW_LAST_VERSION_SEEN = 'WHATS_NEW_LAST_VERSION_SEEN',
  BUDGET_HEADER_DROPDOWN_TRUE_MARGIN = 'BUDGET_HEADER_DROPDOWN_TRUE_MARGIN',
  BUDGET_HEADER_DROPDOWN_MFR = 'BUDGET_HEADER_DROPDOWN_MFR',
  BUDGET_HEADER_DROPDOWN_PASSTHROUGH = 'BUDGET_HEADER_DROPDOWN_PASSTHROUGH',
  BUDGET_HEADER_DROPDOWN_TARGET = 'BUDGET_HEADER_DROPDOWN_TARGET',
  BUDGET_HEADER_DROPDOWN_MARKUP = 'BUDGET_HEADER_DROPDOWN_MARKUP',
  CASHFLOW_SUMMARY_MONEY_IN = 'CASHFLOW_SUMMARY_MONEY_IN',
  CASHFLOW_SUMMARY_MONEY_OUT = 'CASHFLOW_SUMMARY_MONEY_OUT',
  CASHFLOW_SUMMARY_MONEY_OUT_INTERNAL = 'CASHFLOW_SUMMARY_MONEY_OUT_INTERNAL',
  CASHFLOW_SUMMARY_MONEY_OUT_EXTERNAL = 'CASHFLOW_SUMMARY_MONEY_OUT_EXTERNAL',
  CASHFLOW_SUMMARY_INTERNAL_BALANCE = 'CASHFLOW_SUMMARY_INTERNAL_BALANCE',
  CASHFLOW_SUMMARY_EXTERNAL_BALANCE = 'CASHFLOW_SUMMARY_EXTERNAL_BALANCE',
  CASHFLOW_IS_KEEP_ALLOCATION = 'CASHFLOW_IS_KEEP_ALLOCATION',
  CASHFLOW_IS_PAYMENTS_MODE = 'CASHFLOW_IS_PAYMENTS_MODE',
}

export class LocalStorage {
  isReady(): boolean {
    return typeof localStorage !== 'undefined';
  }

  get(key: LocalStorageKeys): any {
    return localStorage.getItem(key);
  }

  set(key: LocalStorageKeys, value: any): void {
    localStorage.setItem(key, value);
  }
}
