import { EventModel } from '@bryntum/scheduler';
import { FC, memo, useState } from 'react';

import Button from '@u9/bob3-shared/lib/components/Button/Button';
import Datepicker from 'components/datepickers/Datepicker/Datepicker';
import FormInput from 'components/inputs/FormInput/FormInput';
import { useField } from 'components/inputs/hooks/useField';
import { useCurrentVersionId } from 'components/modules/Project/hooks/useCurrentVersionId';
import {
  useAddProjectBudgetEntry,
  useDeleteProjectBudgetEntry,
  useUpdateProjectBudgetEntry,
} from 'queries/budgetEntries';
import { useCurrentProject } from 'queries/project';
import { useGlobalStore, useProjectStore } from 'store';
import { getDateString } from 'utils/formatters';
import { ColorNames } from 'utils/styles/theme';

import ConfigModal from '../ConfigModal/ConfigModal';

import * as Styled from './CashflowInvoiceEditModal.styles';

export interface CashflowInvoiceEditModalProps {
  event: EventModel;
}

const getInvoiceDate = (dueDate: string, terms: string) => {
  if (!isNaN(Number(terms))) {
    const invoiceDate = new Date(dueDate);
    invoiceDate.setDate(invoiceDate.getDate() - Number(terms));
    return invoiceDate;
  } else {
    return new Date(dueDate);
  }
};

const getDueDate = (invoiceDate: Date, terms: string) => {
  if (!invoiceDate) {
    return new Date();
  }
  if (!terms) {
    return invoiceDate;
  }
  if (!isNaN(Number(terms))) {
    const dueDate = new Date(invoiceDate);
    dueDate.setDate(dueDate.getDate() + Number(terms));
    return dueDate;
  } else {
    return invoiceDate;
  }
};

const CashflowInvoiceEditModal: FC<CashflowInvoiceEditModalProps> = ({
  event,
}) => {
  const currentColor = useGlobalStore(s => s.currentColor);
  const closeModal = useGlobalStore(s => s.closeModal);

  const [label, setLabel] = useField((event as any).data.label ?? '');
  const [amount, setAmount] = useField((event as any).data.amount ?? 0);
  const [terms, setTerms] = useField((event as any).data.terms ?? '');
  const isCashflowPaymentMode = useProjectStore(s => s.isCashflowPaymentMode);
  const [invoiceDate, setInvoiceDate] = useState(
    (!event as any)?.data?.startDate
      ? (event as any).data.startDate
      : getInvoiceDate(
          (event as any).data.startDate,
          isCashflowPaymentMode ? (event as any).data.terms : 0
        )
  );

  const currentProject = useCurrentProject();
  const currentVersionId = useCurrentVersionId();
  const { mutateAsync: deleteBudgetEntry } = useDeleteProjectBudgetEntry();
  const { mutateAsync: addBudgetEntry } = useAddProjectBudgetEntry();
  const { mutateAsync: updateBudgetEntry } = useUpdateProjectBudgetEntry();

  const close = () => {
    if ((event as any).isNew) {
      event.remove();
    }
    closeModal('cashflowInvoiceEdit');
  };

  const handleSave = async () => {
    const dueDate = getDueDate(invoiceDate, terms);
    const startDate = dueDate;
    const updateData = {
      amount,
      terms,
      label,
      date: getDateString(dueDate),
      startDate,
      endDate: startDate,
    };
    if (currentProject && currentVersionId) {
      if ((event as any).isNew) {
        await addBudgetEntry({
          projectId: currentProject.id,
          projectVersionId: currentVersionId,
          budgetEntry: {
            label,
            invoice_amount: Number(amount),
            terms,
            date: getDateString(invoiceDate),
          },
        });
      } else {
        await updateBudgetEntry({
          projectId: currentProject.id,
          projectVersionId: currentVersionId,
          budgetEntry: {
            pos: Number(event.id),
            label,
            invoice_amount: Number(amount),
            terms,
            date: getDateString(invoiceDate),
          },
        });
      }
      event.set(updateData);
      close();
    }
  };

  const deleteEvent = async () => {
    if (currentProject && currentVersionId) {
      await deleteBudgetEntry({
        projectId: currentProject.id,
        projectVersionId: currentVersionId,
        budgetEntryId: Number(event.id),
      });
      close();
    }
  };

  return (
    <ConfigModal
      title={!(event as any).isNew ? 'Edit Invoice' : 'Add Invoice'}
      onCloseClick={close}
      color={currentColor}
      zeroPadding
    >
      <Styled.Wrapper>
        <Styled.Row>
          <FormInput placeholder="Label" value={label} onChange={setLabel} />
          <FormInput placeholder="Amount" value={amount} onChange={setAmount} />
        </Styled.Row>
        <Styled.Row>
          <Datepicker
            color={currentColor}
            fieldLabel="Invoice Date"
            onChange={setInvoiceDate}
            value={invoiceDate}
          />
          <FormInput placeholder="Terms" value={terms} onChange={setTerms} />
        </Styled.Row>
        <Styled.Buttons>
          <Button
            label={!(event as any).isNew ? 'Save' : 'Add'}
            icon={!(event as any).isNew ? 'check' : 'add'}
            color={currentColor}
            onClick={handleSave}
          />
          {!(event as any).isNew && (
            <Button
              label="Delete"
              color={ColorNames.sunsetOrange}
              icon="close"
              onClick={deleteEvent}
            />
          )}
        </Styled.Buttons>
      </Styled.Wrapper>
    </ConfigModal>
  );
};

export default memo(CashflowInvoiceEditModal);
