import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { FC, useCallback, useEffect } from 'react';

import {
  BreadcrumbsItem,
  BreadcrumbsRoot,
} from 'components/Breadcrumbs/Breadcrumbs';
import Menu from 'components/Menu/Menu';
import WorkspaceSettingsButton from 'components/modules/Admin/WorkspaceSettings/WorkspaceSettingsButton/WorkspaceSettingsButton';
import Notifications from 'components/Notifications/Notifications';
import { useBreadcrumbs } from 'hooks/useBreadcrumbs';
import { useIsDemo } from 'hooks/useIsDemo';
import { useCurrentProject } from 'queries/project';
import { useGlobalStore } from 'store';
import { useSvgIcon } from 'u9/hooks';
import { getAdminRoute, getPerformanceRoute, ROUTES } from 'utils/routes';

import DefaultNavigation from './DefaultNavigation/DefaultNavigation';
import PerformanceFilterButton from './PerformanceFilterButton/PerformanceFilterButton';
import ProjectLabel from './ProjectLabel/ProjectLabel';
import Search from './Search/Search';
import TutorialsDropdown from './TutorialsDropdown/TutorialsDropdown';

import * as Styled from './DefaultLayout.styles';

export interface DefaultLayoutProps {
  children: React.ReactNode | React.ReactNode[];
}

const DefaultLayout: FC<DefaultLayoutProps> = ({ children }) => {
  const currentProject = useCurrentProject();
  const router = useRouter();
  const isNavbarCollapsed = useGlobalStore(s => s.isNavbarCollapsed);
  const setIsNavbarCollapsed = useGlobalStore(s => s.setIsNavbarCollapsed);

  const isDemo = useIsDemo();

  const { SvgIcon: ArrowIcon } = useSvgIcon('double_arrow_left');

  const toggleCollapse = useCallback(() => {
    setIsNavbarCollapsed(!isNavbarCollapsed);
  }, [isNavbarCollapsed, setIsNavbarCollapsed]);

  const hasSearchBar =
    (
      [ROUTES.ADMIN, ROUTES.PERFORMANCE, ROUTES.REPORTS_LIST] as string[]
    ).includes(router.route) &&
    ![
      getAdminRoute('WORKSPACE_SETTINGS'),
      getPerformanceRoute('INSIGHT'),
      getPerformanceRoute('OVERVIEW'),
      getPerformanceRoute('PULSE'),
      getAdminRoute('RESOURCE_DUPLICATES'),
      getAdminRoute('CLIENTS_DUPLICATES'),
      getAdminRoute('BRANDS_DUPLICATES'),
      getAdminRoute('VENDOR_DUPLICATES'),
    ].some(route => router.asPath.startsWith(route));

  const hasPerformanceFilterButton = router.route === ROUTES.PERFORMANCE;
  const hasWorkspaceSettingsButton =
    router.asPath === getAdminRoute('WORKSPACE_SETTINGS');
  const hasProjectLabel = router.route === ROUTES.PROJECT;

  const { breadcrumbs } = useBreadcrumbs();

  const autoCollapseNavbar = useCallback(() => {
    if (router.route === ROUTES.PROJECT) {
      setIsNavbarCollapsed(true);
    }
  }, [router.route, setIsNavbarCollapsed]);

  useEffect(() => {
    router.events.on('routeChangeComplete', autoCollapseNavbar);

    return () => {
      router.events.off('routeChangeComplete', autoCollapseNavbar);
    };
  }, [autoCollapseNavbar, router.events]);

  return (
    <Styled.Wrapper>
      <Styled.Navigation
        isCollapsed={isNavbarCollapsed}
        data-intro="sidebar-navigation"
      >
        <DefaultNavigation
          isNavbarCollapsed={isNavbarCollapsed}
          collapseButton={
            <Styled.CollapseButtonWrapper
              isCollapsed={isNavbarCollapsed}
              onClick={toggleCollapse}
            >
              <Styled.ArrowIconWrapper isCollapsed={isNavbarCollapsed}>
                <ArrowIcon />
              </Styled.ArrowIconWrapper>
            </Styled.CollapseButtonWrapper>
          }
        />
      </Styled.Navigation>
      <Styled.SiteWrapper isNavbarCollapsed={isNavbarCollapsed}>
        <Styled.Header>
          {router.route === ROUTES.PROJECT &&
            currentProject &&
            currentProject.deleted !== true && (
              <Styled.HeaderStart>
                <BreadcrumbsRoot>
                  {breadcrumbs.map(({ href, label }, index) => (
                    <BreadcrumbsItem
                      asChild
                      key={label}
                      lastChild={index === breadcrumbs.length - 1}
                    >
                      <Link href={href}>{label}</Link>
                    </BreadcrumbsItem>
                  ))}
                </BreadcrumbsRoot>
              </Styled.HeaderStart>
            )}
          <Styled.HeaderEnd>
            <Styled.PageHeaderWrapper>
              {hasSearchBar && (
                <Styled.SearchWrapper>
                  <Search />
                </Styled.SearchWrapper>
              )}
              {hasPerformanceFilterButton && (
                <Styled.PerformanceFilterButton>
                  <PerformanceFilterButton />
                </Styled.PerformanceFilterButton>
              )}
              {hasWorkspaceSettingsButton && (
                <Styled.WorkspaceSettingsButton>
                  <WorkspaceSettingsButton />
                </Styled.WorkspaceSettingsButton>
              )}
              {hasProjectLabel && (
                <Styled.ProjectDropdown data-intro="project-header-dropdown">
                  <ProjectLabel />
                </Styled.ProjectDropdown>
              )}
            </Styled.PageHeaderWrapper>
            <Styled.NotificationsWrapper>
              <Notifications />
            </Styled.NotificationsWrapper>
            <Styled.MenuWrapper data-intro="tutorials-menu">
              <TutorialsDropdown />
            </Styled.MenuWrapper>

            <Styled.MenuWrapper>
              <Menu />
            </Styled.MenuWrapper>
          </Styled.HeaderEnd>
        </Styled.Header>
        <Styled.Content>{children}</Styled.Content>
        {isDemo && (
          <Styled.DemoBanner>
            This is a demo version.{' '}
            <span style={{ textDecoration: 'underline', paddingLeft: '20rem' }}>
              <Link
                href={`${process.env.PROXY_WEBSITE_URL}/faq`}
                target="_blank"
              >
                Learn More
              </Link>
            </span>
          </Styled.DemoBanner>
        )}
      </Styled.SiteWrapper>
    </Styled.Wrapper>
  );
};

export default DefaultLayout;
