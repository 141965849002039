import { SortingState } from '@tanstack/react-table';
import { useRouter } from 'next/router';
import { createContext, useContext, useRef } from 'react';
import { createStore, useStore } from 'zustand';

import { DEFAULT_PAGE_SIZE } from 'utils/api';
import { ROUTES } from 'utils/routes';

interface ProjectsListStoreProps {}

export interface ProjectsListStoreType extends ProjectsListStoreProps {
  tablePagination: {
    pageIndex: number;
    pageSize: number;
  };
  setTablePagination: (
    tablePagination: ProjectsListStoreType['tablePagination']
  ) => void;

  tableSorting: SortingState;
  setTableSorting: (
    tableSorting: ProjectsListStoreType['tableSorting']
  ) => void;
}

type ProjectsListStore = ReturnType<typeof createProjectsListStore>;

export const DEFAULT_PROJECTS_LIST_TABLE_PAGINATION = {
  pageIndex: 0,
  pageSize: DEFAULT_PAGE_SIZE,
};

const createProjectsListStore = (
  initProps: ProjectsListStoreProps,
  { isJobClosurePendingRoute }: { isJobClosurePendingRoute: boolean }
) => {
  return createStore<ProjectsListStoreType>()(set => ({
    tablePagination: DEFAULT_PROJECTS_LIST_TABLE_PAGINATION,
    setTablePagination: tablePagination => {
      set(() => ({
        tablePagination,
      }));
    },

    tableSorting: isJobClosurePendingRoute
      ? [{ id: 'closure_state', desc: true }]
      : [{ id: 'updated_at', desc: true }],
    setTableSorting: tableSorting => {
      set(() => ({
        tableSorting,
      }));
    },
  }));
};

export const ProjectsListStoreContext = createContext<ProjectsListStore | null>(
  null
);

export default function useProjectsListStore<T>(
  selector: (state: ProjectsListStoreType) => T,
  equalityFn?: (left: T, right: T) => boolean
): T {
  const store = useContext(ProjectsListStoreContext);
  if (!store) throw new Error('Missing GlobalStore.Provider in the tree');
  return useStore(store, selector, equalityFn);
}

export const useInitProjectsListStore = (initProps: ProjectsListStoreProps) => {
  const router = useRouter();
  const storeRef = useRef<ProjectsListStore>();
  const isJobClosurePendingRoute =
    router.route === ROUTES.PROJECTS_LIST_JOB_CLOSURE_PENDING;
  if (!storeRef.current) {
    storeRef.current = createProjectsListStore(initProps, {
      isJobClosurePendingRoute,
    });
  }

  return storeRef;
};
