import { FC } from 'react';

import OverflowEllipsis from 'components/OverflowEllipsis/OverflowEllipsis';
import { useJobNumberPrefix } from 'hooks/useJobNumberPrefix';
import { useCurrentProject } from 'queries/project';

import * as Styled from './ProjectLabel.styles';

export interface ProjectLabelProps {}

const ProjectLabel: FC<ProjectLabelProps> = () => {
  const currentProject = useCurrentProject();
  const { getJobNumberForStatus } = useJobNumberPrefix();

  return (
    <Styled.Wrapper>
      {(currentProject?.job_number || currentProject?.pitch_number) && (
        <Styled.HeaderText>
          {getJobNumberForStatus(currentProject)}
        </Styled.HeaderText>
      )}
      {currentProject?.client?.id && (
        <Styled.HeaderText>
          <OverflowEllipsis text={currentProject?.client.name} />
        </Styled.HeaderText>
      )}
      {currentProject?.brand?.id && (
        <Styled.HeaderText>
          <OverflowEllipsis text={currentProject?.brand.name} />
        </Styled.HeaderText>
      )}
      <Styled.HeaderText>
        <OverflowEllipsis text={currentProject?.name} />
      </Styled.HeaderText>
    </Styled.Wrapper>
  );
};

export default ProjectLabel;
