import styled from 'styled-components';

import { setTypography } from 'utils/styles/mixins';

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 60rem;
  width: 1600rem;
`;

export const Description = styled.div`
  ${({ theme: { locale } }) => setTypography('body3', locale)};
`;

export const NameField = styled.div``;

export const AccessRights = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10rem;
`;
