import Link from 'next/link';
import { FC, memo } from 'react';

import Copy from 'components/Copy/Copy';
import { useNotificationHover } from 'components/Notifications/hooks/useNotificationHover';
import { useCopyStore } from 'store';
import { VendorDeclinedNotification } from 'utils/api.types';
import { ROUTES } from 'utils/routes';

import * as Styled from '../../NotificationItem.styles';

export interface VendorRequestDeclinedNotificationProps {
  notification: VendorDeclinedNotification;
}

const VendorRequestDeclinedNotification: FC<
  VendorRequestDeclinedNotificationProps
> = ({ notification }) => {
  const copy = useCopyStore(s => s.copy);
  const { onMouseEnter, onMouseLeave, isHovered } = useNotificationHover();

  return (
    <Styled.Wrapper onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
      <Copy
        text={copy.app.notifications.vendorDeclinedNotification}
        replacements={[
          ['{vendor}', notification.extra_info.vendorName],
          [
            '{button}',
            <Link
              href={{
                pathname: ROUTES.PROJECTS_LIST,
                query: {
                  vendorIds: notification.extra_info.vendorId,
                },
              }}
              key="button"
              prefetch={false}
            >
              <Styled.NotificationInlineButton underlined={isHovered}>
                {copy.app.notifications.vendorDeclinedButton}
              </Styled.NotificationInlineButton>
              ,
            </Link>,
          ],
        ]}
      />
    </Styled.Wrapper>
  );
};

export default memo(VendorRequestDeclinedNotification);
